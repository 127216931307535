import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export const PaginationControls = (props) => {

    const handlePageClick = async (e, index) => {
        e.preventDefault();
        props.setCurrentPage(index);
    };

    return (
        <Pagination size="lg">
            <PaginationItem>
                <PaginationLink
                    first
                    href="#"
                    onClick={e => handlePageClick(e, 0)}
                />
            </PaginationItem>
            <PaginationItem disabled={props.currentPage === 0}>
                <PaginationLink
                    href="#"
                    previous
                    onClick={e => handlePageClick(e, props.currentPage - 1)}

                />
            </PaginationItem>
            {props.shoots && [...Array(props.shoots.pageCount)].map((page, i) => (
                <PaginationItem active={i === props.currentPage} key={i}>
                    <PaginationLink onClick={e => handlePageClick(e, i)} href="#">
                        {i + 1}
                    </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem disabled={props.shoots && props.currentPage === props.shoots.pageCount - 1}>
                <PaginationLink
                    href="#"
                    next
                    onClick={e => handlePageClick(e, props.currentPage + 1)}
                />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink
                    href="#"
                    last
                    onClick={e => handlePageClick(e, props.shoots.pageCount - 1)}
                />
            </PaginationItem>
        </Pagination>
    );
};

export default PaginationControls;
