import React, {useEffect, useState, useCallback} from "react";
import Loading from "../../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import UnbookedTable from "./tables/UnbookedTable";
import SubmittedTable from "./tables/SubmittedTable";
import ActiveTable from "./tables/ActiveTable";
import CompletedTable from "./tables/CompletedTable";
import 'react-tabs/style/react-tabs.css';
import CancelledTable from "./tables/CancelledTable";
import {Badge} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {determineIfTokenError} from "../../store/AuthThunks";
import {callRemote} from "../../service/RemoteService";

export const ProfileComponent = () => {
    let dispatch = useDispatch();
    const { loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

    const [photographers, setPhotographers] = useState();
    const [shootTotals, setShootTotals] = useState();

    const shootsActionTaken = useSelector((state) => state.shoots.actionTakenNum);

    const getPhotographers = useCallback(() => {
        callRemote(getAccessTokenSilently, 'get', "/photographers/")
        .then(resp => {
            setPhotographers(resp.map(
                p => { return { value: p.id, label: p.name, data: p } })
            );
        }).catch(e => {
            dispatch(determineIfTokenError(e, loginWithRedirect, logout));
        });
    }, [dispatch, setPhotographers, loginWithRedirect, logout, getAccessTokenSilently]);

    const getShootTotals = useCallback(() => {
        callRemote(getAccessTokenSilently, 'get', "/shoots/getShootTotals")
        .then(totalsResp => {
            setShootTotals(totalsResp);
        }).catch(e => {
            //should have some kind of error alert
            dispatch(determineIfTokenError(e, loginWithRedirect, logout));
        });
    }, [dispatch, setShootTotals, loginWithRedirect, logout, getAccessTokenSilently]);

    useEffect(() => {
        getPhotographers();
        getShootTotals();
    }, [shootsActionTaken, getPhotographers, getShootTotals]);

    const TabLabel = (props) => {
        return (
            <span>
                { props.assignTotal > 0 &&
                    <Badge color="danger" style={{marginRight: '5px'}}>
                        {props.assignTotal}
                    </Badge>
                }
                {props.label}
            </span>
        );
    }

    return (
        <div>
            { shootTotals &&
                <Tabs>
                    <TabList>
                        <Tab><TabLabel assignTotal={shootTotals.assignTotal} label={"Assign Shoots"} /></Tab>
                        <Tab><TabLabel assignTotal={shootTotals.payTotal} label={"Pay Photographers"} /></Tab>
                        <Tab><TabLabel assignTotal={shootTotals.activeTotal} label={"Active Shoots"} /></Tab>
                        <Tab><TabLabel assignTotal={shootTotals.completedTotal} label={"Completed Shoots"} /></Tab>
                        <Tab><TabLabel assignTotal={shootTotals.cancelledTotal} label={"Cancelled Shoots"} /></Tab>
                    </TabList>

                    <TabPanel><UnbookedTable photographers={photographers}/></TabPanel>
                    <TabPanel><SubmittedTable/></TabPanel>
                    <TabPanel><ActiveTable photographers={photographers}/></TabPanel>
                    <TabPanel><CompletedTable/></TabPanel>
                    <TabPanel><CancelledTable/></TabPanel>
                </Tabs>
            }
        </div>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <Loading />,
});
