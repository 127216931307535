import {authActions} from "./storeIndex";
import {displayAlert} from "./AlertSlice";

export const logoutAndClearAllSlices = (logout) => async (dispatch) => {
    try {
        //clear any and all global states here
        dispatch(authActions.logout());
        /*logout({
            returnTo: window.location.origin,
        });*/
    } catch (e) {
        dispatch(displayAlert(false, "error logging out"))
    }
}

export const determineIfTokenError = (error) => async (dispatch, getState) => {
    dispatch(displayAlert(false, error.message));
}