import React, { useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import MyShoots from "./views/photographers/MyShoots";
import PhotographerJobs from "./views/PhotographerJobs";
import PhotographersSearch from "./views/photographers/PhotographersSearch";
import PhotographersApply from "./views/PhotographersApply";
import Redirect from "./views/Redirect";
import SignAgreement from "./views/SignAgreement";
import Shoots from "./views/photographers/Shoots";
import StripeSetup from "./views/StripeSetup";
import Jobs from "./views/jobs/Jobs";
import MyJobs from "./views/jobs/MyJobs";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";
import { ErrorBoundary } from 'react-error-boundary'
// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import { useDispatch } from "react-redux";
import { isExpired } from "react-jwt";
import { authActions } from "./store/storeIndex";
import AlertPopup from "./components/AlertPopup";
import PhotoUploads from "./views/photographers/PhotoUploads";
import ManageJobs from "./views/jobsManagement/ManageJobs";

initFontAwesome();

const App = () => {
  let dispatch = useDispatch();

  const { isLoading, error, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let token = sessionStorage.getItem("token")
    if (token && !isExpired(token)) {
      dispatch(authActions.loginSuccess({ token: token }));
    } else if (isAuthenticated) {
      getAccessTokenSilently({ audience: "https://team.exoticcartrader.com/" })
        .then(auth => {
          dispatch(authActions.loginSuccess({ token: auth }));
        });
    }
  }, [isAuthenticated, dispatch, getAccessTokenSilently]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  function ErrorFallbackComponent({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  //Needed for a trick to let the router work when loading in from redirects. DFWT
  const path = (/#!(\/.*)$/.exec(window.location) || [])[1];
  if (path) {
    history.replace(path);
  }


  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallbackComponent}
      onReset={() => {
        window.location.reload();
      }}
    >
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1 mt-5">
            <AlertPopup />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/index.html" exact component={Home} />
              <Route path="/profile" component={Profile} />
              <Route path="/shoots" component={Shoots} />
              <Route path="/my-shoots" component={MyShoots} />
              <Route path="/photographers-apply" component={PhotographersApply} />
              <Route path="/photographer-search" component={PhotographersSearch} />
              <Route path="/photographers-jobs" component={PhotographerJobs} />
              <Route path="/stripe-setup" component={StripeSetup} />
              <Route path="/redirect" component={Redirect} />
              <Route path="/sign-agreement" component={SignAgreement} />
              <Route path="/jobs" component={Jobs} />
              <Route path="/my-jobs" component={MyJobs} />
              <Route path="/manage-jobs" component={ManageJobs} />
              <Route path="/upload-photos/:id/:car" component={PhotoUploads} />
            </Switch>
          </Container>
          <Footer />
        </div>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
