import React, { useState, useEffect, useCallback } from "react";
import { Button, ButtonGroup, Table, UncontrolledTooltip } from "reactstrap";
import dayjs from 'dayjs'
import { useAuth0 } from "@auth0/auth0-react";
import { callRemote, GET } from "../../../service/RemoteService";
import { determineIfTokenError } from "../../../store/AuthThunks";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobCancelModal from "../modals/JobCancelModal";
import JobSubmitModal from "../modals/JobSubmitModal";

export const MyjobsTable = () => {
    let dispatch = useDispatch();
    const { user, getAccessTokenSilently } = useAuth0();
    const [jobs, setjobs] = useState(null);
    const [submitModalState, setSubmitModalState] = useState({ isOpen: false });
    const [cancelModalState, setCancelModalState] = useState({ isOpen: false });

    const getJobs = useCallback(async () => {
        callRemote(getAccessTokenSilently, GET, "/jobs/my-jobs")
            .then((jobsResponse) => {
                setjobs(jobsResponse);
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            });
    }, [dispatch, getAccessTokenSilently]);

    useEffect(() => {
        getJobs();
    }, [getJobs]);

    const openSubmitModal = async (job) => {
        setSubmitModalState({ isOpen: true, job: job });
    }
    const closeSubmitModal = () => {
        setSubmitModalState({ isOpen: false });
    }

    const openCancelModal = async (job) => {
        setCancelModalState({ isOpen: true, job: job });
    }
    const closeCancelModal = () => {
        setCancelModalState({ isOpen: false });
    }

    const roles = user != null && user["http://exoticcartrader.com/role"];
    const isEditor = roles.includes('Editor');
    const isBlogger = roles.includes('Blogger');
    const isUploader = roles.includes('Uploader');

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Submitted Date</th>
                        <th>Car</th>
                        <th>VIN/Lot Number</th>
                        {isEditor ? (<th>URL String</th>) : null}
                        <th>Actions</th>
                        <th>Links</th>
                    </tr>
                </thead>
                <tbody>{jobs != null && jobs.jobs.map(job => (
                    <tr key={job.id}>
                        <td>{dayjs(job.photosSubmittedDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{job.car}</td>
                        <td>{job.vin}<br/>{job.lotNumber}</td>
                        {isEditor ? (<td>{job.urlString}</td>) : null}
                        <td>
                            <ButtonGroup style={{marginBottom: '10px'}}>
                                <Button onClick={() => openSubmitModal(job)} id={'submitBtn'} color="success">
                                    <FontAwesomeIcon icon="check" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="submitBtn"
                                        trigger="hover"
                                    >
                                        Submit
                                    </UncontrolledTooltip>
                                </Button>
                                <Button onClick={() => openCancelModal(job)} id={'cancelBtn'} color="danger">
                                    <FontAwesomeIcon icon="ban" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target="cancelBtn"
                                        trigger="hover"
                                    >
                                        Cancel
                                    </UncontrolledTooltip>
                                </Button>
                            </ButtonGroup>
                            { job.rejectionReason ? (
                                <ButtonGroup>
                                <Button onClick={() => {}} id={'infoBtn-'+job.id} color="danger" >
                                    <FontAwesomeIcon icon="exclamation-circle" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target={"infoBtn-"+job.id}
                                        trigger="hover"
                                    >
                                        Rejected - Please correct using the notes below.  After you correct, then re-submit: {job.rejectionReason}
                                    </UncontrolledTooltip>
                                </Button>
                            </ButtonGroup>) : null

                            }

                        </td>
                        <td>
                            <a href={job.photosLink} target="_blank" rel="noopener noreferrer">Photographer Photos</a> <br />
                            {isEditor ? (<a href={"https://my.sirv.com/#/browse/" + job.sirvFolder} target="_blank" rel="noopener noreferrer">Upload Edited Photos</a>) : null} <br />
                            {isBlogger || isUploader ? (<a href={job.instructionLink} target="_blank" rel="noopener noreferrer">Listing Instructions</a>) : null} <br />
                            <br />
                        </td>
                    </tr>
                ))
                }
                </tbody>
            </Table>

            <JobSubmitModal
                photographerId={user.sub}
                job={submitModalState.job}
                isOpen={submitModalState.isOpen}
                getJobs={getJobs}
                closeModal={closeSubmitModal}
                setModalState={setSubmitModalState}
            />
            <JobCancelModal
                job={cancelModalState.job}
                isOpen={cancelModalState.isOpen}
                getJobs={getJobs}
                closeModal={closeCancelModal}
                setModalState={setCancelModalState}
            />
        </div>
    );
};

export default MyjobsTable;
