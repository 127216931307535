import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {callRemote, POST} from "../../../service/RemoteService";
import {shootsActions} from "../../../store/storeIndex";
import {displayAlert} from "../../../store/AlertSlice";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";

export const UndoCancelModal = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const submit = async () => {
        callRemote(getAccessTokenSilently, POST, "/shoots/" + props.shoot.id + "/reactivate")
        .then(() => {
            dispatch(shootsActions.incrementActionsTaken());
            dispatch(displayAlert(true, "cancelled shoot has been reactivated"));
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        }).finally(() => {
            props.getShoots(0);
            props.setModalState({ isOpen: false });
        })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Reactivate Shoot</ModalHeader>
            <ModalBody>
                <h2>Do you really want to re-activate this shoot?</h2>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => submit()} color="success">Yes</Button>
                <Button onClick={() => props.setModalState({ isOpen: false })} color="danger">No</Button>
            </ModalFooter>
        </Modal>
    );
};

export default UndoCancelModal;
