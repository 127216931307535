import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from "axios";
import states from "./states.json";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ProfileComponent = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [profile, setProfile] = useState(null);
  const [validation, setValidation] = useState({
    street: true,
    city: true,
    zip: true,
    phoneNumber: true
});

  useEffect(() => {
    const getProfile = async () => {
      const token = await getAccessTokenSilently({ audience: "https://team.exoticcartrader.com/" });
      const shootsResponse = await axios.get(process.env.REACT_APP_API_BASE_URL + "/photographers/get", { headers: { Authorization: "Bearer " + token } });
      setProfile(shootsResponse.data);
    };
    getProfile();
  }, [getAccessTokenSilently]);

  const handleInputChange = e => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
    setValidation({ ...validation, [e.target.name]: e.target.value !== '' });
  };

  const submit = async () => {
    if(!validate()) {
      return;
  }
    const token = await getAccessTokenSilently({ audience: "https://team.exoticcartrader.com/" });
    await axios.put(process.env.REACT_APP_API_BASE_URL + "/photographers/update", profile, { headers: { Authorization: "Bearer " + token } });
  };

  const validate = () => {
    const newValidation = {
      street: profile.street !== '',
      city: profile.city !== '',
      zip: /^[0-9]+$/.test(profile.zip) && profile.zip.length === 5,
      phoneNumber: /^[0-9]+$/.test(profile.phoneNumber) && profile.phoneNumber.length === 10
    };
    setValidation(
        newValidation
    )
    return Object.values(newValidation).every(Boolean);
}

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row>
        {profile && (
          <Form>
            <FormGroup>
              <Label for="street">Street</Label>
              <Input type="text" name="street" id="street" value={profile.street} onChange={handleInputChange} invalid={!validation.street}/>
            </FormGroup>
            <FormGroup>
              <Label for="city">City</Label>
              <Input type="city" name="city" id="city" value={profile.city} onChange={handleInputChange} invalid={!validation.city}/>
            </FormGroup>
            <FormGroup>
              <Label for="state">State</Label>
              <Input type="select" name="state" id="state" value={profile.state} onChange={handleInputChange}>
                {states.map(item => (
                  <option key={item.abbreviation} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="zip">ZIP</Label>
              <Input type="zip" name="zip" id="zip" value={profile.zip} onChange={handleInputChange} invalid={!validation.zip}/>
            </FormGroup>
            <FormGroup>
              <Label for="phoneNumber">Phone</Label>
              <Input type="text" name="phoneNumber" id="phoneNumber" value={profile.phoneNumber} onChange={handleInputChange} invalid={!validation.phoneNumber}/>
            </FormGroup>
            <Button onClick={submit}>Save</Button>
          </Form>
        )}
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
