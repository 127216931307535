import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Content = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div className="next-steps my-5">
      {isAuthenticated ? null : (<h2 className="my-5 text-center">Press "Log in" in the top right to either log in or sign up with Exotic Car Trader</h2>)}
    </div>
  );
}

export default Content;
