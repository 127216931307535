import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Fragment, useEffect, useState, useCallback} from "react";
import {Alert} from "reactstrap";
import {alertActions} from "../store/storeIndex";

const AlertPopup = () => {
    let dispatch = useDispatch();

    const {showAlert, success, message} = useSelector((state) => state.alert);

    const [showToastState, setShowToastState] = useState(showAlert);

    const handleClose = useCallback(() => {
        dispatch(alertActions.hideAlert());
        setShowToastState(false);
    }, [dispatch, setShowToastState]);
    
    useEffect(() => {
        setShowToastState(showAlert);
        setTimeout(() => {
            handleClose();
        }, 20000);
    }, [showAlert, handleClose]);

    return (
        <Fragment>
            { showToastState &&
                <Alert color={success ? "success" : "danger"}>
                    <h6 className="alert-heading">
                        {success ? "Success" : "Error"}
                    </h6>
                    <p>{message}</p>
                </Alert>
            }
        </Fragment>
    );
}

export default AlertPopup;