import React from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import {shootsActions} from "../../../store/storeIndex";
import {displayAlert} from "../../../store/AlertSlice";
import {useDispatch} from "react-redux";
import {callRemote, PUT} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";

export const JobCancelModal = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const submit = async () => {
        const job = props.job;
        job.creatorId = null;

        callRemote(getAccessTokenSilently, PUT, "/jobs/" + props.job.id, { ...job })
            .then(() => {
                dispatch(shootsActions.incrementActionsTaken());
                dispatch(displayAlert(true, "Cancelled Job"))
            }).catch(e => {
                dispatch(determineIfTokenError(e));
        }).finally(() => {
            props.getJobs();
            props.setModalState({ isOpen: false });
        })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Cancel Job</ModalHeader>
            <ModalBody>
                <h2>Do you really want to cancel this Job?</h2>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => submit()} color="success">Yes</Button>
                <Button onClick={() => props.setModalState({ isOpen: false })} color="danger">No</Button>
            </ModalFooter>
        </Modal>
    );
};

export default JobCancelModal;
