import React from "react";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import { withRouter } from "react-router";

export const ProfileComponent = (props) => {
    const { user, getAccessTokenSilently } = useAuth0();

    const redirect = async () => {
        const token = await getAccessTokenSilently({ audience: "https://team.exoticcartrader.com/" });
        const statusRespose = await axios.get(process.env.REACT_APP_API_BASE_URL + "/photographers/status", { headers: { Authorization: "Bearer " + token } });

        if(!statusRespose.data.submittedApplication) {
            props.history.push('/photographers-apply');
        } else if (!statusRespose.data.agreementSigned) {
            props.history.push('/sign-agreement');
        }
         else if(!statusRespose.data.stripeSetup) {
            props.history.push('/stripe-setup');
        }
         else {
            props.history.push('/');
        }
    }
    
    const roles = user != null && user["http://exoticcartrader.com/role"];
    if (roles.includes("Photographer")) {
        redirect();
    } else {
        props.history.push('/');
    }

    return (
        <div>
         <Loading/>
        </div>
    );
};

export default withAuthenticationRequired(withRouter(ProfileComponent), {
    onRedirecting: () => <Loading />,
});
