import {configureStore} from "@reduxjs/toolkit";
import {AuthSlice} from "./AuthSlice";
import {AlertSlice} from "./AlertSlice";
import {ShootsSlice} from "./ShootsSlice";

const store = configureStore({
    reducer: {
        alert: AlertSlice.reducer,
        auth: AuthSlice.reducer,
        shoots: ShootsSlice.reducer,
    }
});

export const alertActions = AlertSlice.actions;
export const authActions = AuthSlice.actions;
export const shootsActions = ShootsSlice.actions;

export default store;

