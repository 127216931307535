import React, { useState, useEffect, useCallback } from "react";
import { Table } from "reactstrap";
import dayjs from 'dayjs'
import { useAuth0 } from "@auth0/auth0-react";
import { callRemote, GET } from "../../../service/RemoteService";
import { determineIfTokenError } from "../../../store/AuthThunks";
import { useDispatch } from "react-redux";
import PaginationControls from "../../photographers/tables/PaginationControls";

export const MyjobsTable = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const [jobs, setjobs] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const getJobs = useCallback(async () => {
        callRemote(getAccessTokenSilently, GET, "/jobs/my-submitted-jobs?pageNumber=" + currentPage)
            .then((jobsResponse) => {
                setjobs(jobsResponse);
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            });
    }, [dispatch, getAccessTokenSilently, currentPage]);

    useEffect(() => {
        getJobs();
    }, [getJobs]);

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Submitted Date</th>
                        <th>Car</th>
                        <th>VIN</th>
                    </tr>
                </thead>
                <tbody>{jobs != null && jobs.jobs.map(job => (
                    <tr key={job.id}>
                        <td>{dayjs(job.submittedDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{job.car}</td>
                        <td>{job.vin}</td>
                    </tr>
                ))
                }
                </tbody>
            </Table>
            <PaginationControls getJobs={getJobs} jobs={jobs} currentPage={currentPage} setCurrentPage={setCurrentPage} />

        </div>
    );
};

export default MyjobsTable;
