import axios from "axios";
import {isExpired} from "react-jwt";

export const callRemote = async (getAccessTokenSilently, type, path, data) => {
    if (isTokenNotExpired()) {
        return type.toLowerCase() === 'get' ? callGetRemote(path) : callRemoteWithReqBody(type, path, data);
    }

    await getAccessTokenSilently({audience: "https://team.exoticcartrader.com/"})
        .then(auth => {
            console.log('in the then')
            sessionStorage.setItem('token', auth);
        });
    return type.toLowerCase() === 'get' ? callGetRemote(path) : callRemoteWithReqBody(type, path, data);
}

const isTokenNotExpired = () => {
    let token = sessionStorage.getItem("token")
    return token && !isExpired(token);
}

const callGetRemote = (path) => {
    return axios.get(
        process.env.REACT_APP_API_BASE_URL + path,
        { headers: { Authorization: "Bearer " + sessionStorage.getItem('token') } }
    )
    .then(handleApiError)
    .then(async (response) => await response.data)
    .catch(e => {throw e});
}

const callRemoteWithReqBody = (type, path, data) => {
    return axios[type](
        process.env.REACT_APP_API_BASE_URL + path,
        data,
        { headers: { Authorization: "Bearer " + sessionStorage.getItem('token') } }
    )
    .then(handleApiError)
    .then(async (response) => await response.data)
    .catch(e => {throw e});
}

async function handleApiError(response) {
    if (response.status !== 200) {
        if (response.status === 404) {
            throw Error("There was an unexpected network failure");
        }
        let errorResponse = await response.data;
        throw Error(errorResponse.message);
    }
    return response;
}

export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';