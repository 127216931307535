import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, Table } from "reactstrap";
import Select from 'react-select'
import { useAuth0 } from "@auth0/auth0-react";
import { alertActions, shootsActions } from "../../../store/storeIndex";
import { useDispatch } from "react-redux";
import { callRemote, PUT, POST } from "../../../service/RemoteService";
import { determineIfTokenError } from "../../../store/AuthThunks";

export const AssignModal = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const [selectedOption, setSelectedOption] = useState(null);
    const [eachEntry, setEachEntry] = useState({notes: ''});

    const assign = async (photographerid, photographerName) => {
        const shoot = props.shoot;
        shoot.photographerId = photographerid;
        shoot.photographerName = photographerName;
        shoot.notes = eachEntry.notes;

        callRemote(getAccessTokenSilently, PUT, "/shoots/" + shoot.id, { ...shoot })
            .then(() => {
                dispatch(alertActions.showAlert({
                    success: true,
                    message: `assigned shoot: ${shoot.id} to photographer: ${photographerName}`
                }));
                dispatch(shootsActions.incrementActionsTaken());
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            }).finally(() => {
                props.getShoots();
                props.closeModal();
            })
    }


    const doSetSelectedOption = async (photographer) => {
        callRemote(getAccessTokenSilently, POST, "/photographers/get", { id: photographer.data.id })
            .then((data) => {
                photographer.data.city = data.city;
                photographer.data.state = data.state;
                photographer.data.zip = data.zip;
                setSelectedOption(photographer)
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            })
    }

    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
    };

    return (
        <>
            { props.shoot &&
            <Modal isOpen={props.isOpen} toggle={props.closeModal} size="lg">
                <ModalHeader toggle={props.closeModal}>Assign Photographer</ModalHeader>
                <ModalBody>
                    <p>Vehicle: {props.shoot.car}</p>
                    <p>Vin: {props.shoot.vin}</p>
                    <p>Customer: {props.shoot.customerName}</p>
                    <p>Current Photographer: {props.shoot.photographerName}</p>
                    <Select options={props.photographers} isSearchable={true} onChange={doSetSelectedOption} />
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zip</th>
                        </tr>
                        </thead>
                        {selectedOption && (<tbody>
                            <tr key={selectedOption.data.id}>
                                <td>{selectedOption.data.name}</td>
                                <td>{selectedOption.data.email}</td>
                                <td>{selectedOption.data.phoneNumber}</td>
                                <td>{selectedOption.data.city}</td>
                                <td>{selectedOption.data.state}</td>
                                <td>{selectedOption.data.zip}</td>
                            </tr>

                            </tbody>
                        )}
                    </Table>
                    <Form>
                        <FormGroup>
                            <Label for="notes">Notes</Label>
                            <Input type="text" name="notes" id="notes" placeholder="Notes" onChange={handleInputChange} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => assign(selectedOption.value, selectedOption.label)} color="primary" disabled={selectedOption == null}>Assign</Button>
                    <Button onClick={() => props.setModalState({ isOpen: false })}>Cancel</Button>
                </ModalFooter>
            </Modal> }
        </>
    );
};

export default AssignModal;
