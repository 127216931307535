import React, { useState, useEffect, useCallback } from "react";
import { Button, Table } from "reactstrap";
import AssignModal from "../modals/AssignModal";
import PaginationControls from "./PaginationControls";
import { useAuth0 } from "@auth0/auth0-react";
import {callRemote, GET} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";
import dayjs from 'dayjs'

export const UnbookedTable = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const [currentPage, setCurrentPage] = useState(0);
    const [shoots, setShoots] = useState(null);
    const [assignModalState, setAssignModalState] = useState({ isOpen: false });

    const getShoots = useCallback(async () => {
        callRemote(getAccessTokenSilently, GET, "/shoots/?status=unbooked&pageNumber=" + currentPage)
        .then((shootsResponse) => {
            setShoots(shootsResponse);
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        });
    }, [currentPage, dispatch, getAccessTokenSilently])

    useEffect(() => {
        getShoots();
    }, [getShoots]);

    const openAssignModal = async (shoot) => {
        setAssignModalState({ isOpen: true, shoot: shoot });
    }
    const closeAssignModal = () => {
        setAssignModalState({ isOpen: false });
    }

    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Signing Date</th>
                        <th>Vehicle</th>
                        <th>VIN</th>
                        <th>Customer</th>
                        <th>Vehicle Location</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>{shoots != null && shoots.shoots.map(shoot => (
                    <tr key={shoot.id}>
                        <td>{dayjs(shoot.signingDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{shoot.car}</td>
                        <td>{shoot.vin}</td>
                        <td>{shoot.customerName}</td>
                        <td>{shoot.city + ", " + shoot.state}</td>
                        <td>{<Button onClick={() => openAssignModal(shoot)} color="primary">Assign</Button>}</td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <PaginationControls getShoots={getShoots} shoots={shoots} currentPage={currentPage} setCurrentPage={setCurrentPage} />

            <AssignModal
                photographers={props.photographers}
                isOpen={assignModalState.isOpen}
                getShoots={() => getShoots(currentPage)}
                closeModal={closeAssignModal}
                setModalState={setAssignModalState}
                shoot={assignModalState.shoot}
            />
        </div>
    );
};

export default UnbookedTable;
