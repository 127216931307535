import React, {useState} from "react";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {shootsActions} from "../../store/storeIndex";
import {displayAlert} from "../../store/AlertSlice";
import {useDispatch} from "react-redux";
import {callRemote, POST} from "../../service/RemoteService";
import {determineIfTokenError} from "../../store/AuthThunks";

export const JobApproveModal = (props) => {
    const job = props.job;

    let dispatch = useDispatch();
    const {getAccessTokenSilently} = useAuth0();
    const [eachEntry, setEachEntry] = useState({
        overview: job.overview,
        interiorExterior: job.interiorExterior,
        engine: job.engine
    });
    const [validation, setValidation] = useState({
        overview: true,
        interiorExterior: true,
        engine: true
    });
    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
        setValidation({ ...validation, [e.target.name]: e.target.value !== '' });
    };


    const isEditor = job.jobType === "Editor";
    const isBlogger = job.jobType === "Blogger";
    const submit = () => {
        callRemote(getAccessTokenSilently, POST, "/jobs/" + job.id + "/approve", {...eachEntry})
            .then(() => {
                dispatch(shootsActions.incrementActionsTaken());
                dispatch(displayAlert(true, "Approved Job"))
            }).catch(e => {
            dispatch(determineIfTokenError(e));
        }).finally(() => {
            props.getJobs();
            props.setModalState({isOpen: false});
        })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Approve Submission</ModalHeader>
            <ModalBody>
                {isEditor ? (<>
                        <h4>Notice: You are about to approve the submitted photos.</h4>
                        <h6>Only submit this if the photos and file names are all correct</h6>
                    </>

                ) : null}
                {isBlogger ? (
                    <Form>
                        <FormGroup>
                            <Label for="overview">Overview</Label>
                            <Input type="textarea" name="overview" id="overview" onChange={handleInputChange} invalid={!validation.overview} value={eachEntry.overview}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="interiorExterior">Interior/Exterior</Label>
                            <Input type="textarea" name="interiorExterior" id="interiorExterior" onChange={handleInputChange} invalid={!validation.interiorExterior} value={eachEntry.interiorExterior}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="engine">Engine</Label>
                            <Input type="textarea" name="engine" id="engine" onChange={handleInputChange} invalid={!validation.engine} value={eachEntry.engine}/>
                        </FormGroup>
                    </Form>) : null}

            </ModalBody>
            <ModalFooter>
                <Button onClick={submit} color="success">Approve</Button>
                <Button onClick={() => props.setModalState({isOpen: false})} color="danger">Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default JobApproveModal;
