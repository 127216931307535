import {createSlice} from "@reduxjs/toolkit";

const shootsStateInit = {
    actionTakenNum: 0,
}

export const ShootsSlice = createSlice({
    name: 'ShootsSlice',
    initialState: shootsStateInit,
    reducers: {
        incrementActionsTaken(state) {
            state.actionTakenNum = state.actionTakenNum + 1;
        }
    }
});