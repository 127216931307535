import {createSlice} from "@reduxjs/toolkit";
import {alertActions} from "./storeIndex";

const alertStateInit = {
    showAlert:false,
    success:true,
    message:''
}

export const AlertSlice = createSlice({
    name:'AlertSlice',
    initialState:alertStateInit,
    reducers: {
        showAlert(state, action) {
            state.showAlert = true;
            state.success = action.payload.success;
            state.message = action.payload.message;
        },
        hideAlert(state) {
            state.showAlert = false;
        }
    }
});

export const displayAlert = (success, message) => async (dispatch) => {
    dispatch(alertActions.showAlert({
        success: success,
        message: message
    }));
}