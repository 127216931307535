import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { callRemote, POST } from "../../../service/RemoteService";
import { shootsActions } from "../../../store/storeIndex";
import { displayAlert } from "../../../store/AlertSlice";
import { determineIfTokenError } from "../../../store/AuthThunks";
import { useDispatch } from "react-redux";

export const JobSubmitModal = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently, user } = useAuth0();

    const roles = user != null && user["http://exoticcartrader.com/role"];
    const isEditor = roles.includes('Editor');
    const isBlogger = roles.includes('Blogger');
    const [eachEntry, setEachEntry] = useState({
        overview: '',
        interiorExterior: '',
        engine: ''
    });
    const submit = async () => {
        if (isBlogger && !validate()) {
            return;
        }
        
        callRemote(getAccessTokenSilently, POST, "/jobs/" + props.job.id + "/submit", { ...eachEntry })
            .then(() => {
                dispatch(shootsActions.incrementActionsTaken());
                dispatch(displayAlert(true, "Job Submitted"));
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            }).finally(() => {
                props.getJobs();
                props.setModalState({ isOpen: false });
            })
    }


    const [validation, setValidation] = useState({
        overview: true,
        interiorExterior: true,
        engine: true
    });
    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
        setValidation({ ...validation, [e.target.name]: e.target.value !== '' });
    };
    const validate = () => {
        const newValidation = {
            overview: eachEntry.overview !== '',
            interiorExterior: eachEntry.interiorExterior !== '',
            engine: eachEntry.engine !== ''
        };
        setValidation(newValidation);
        return Object.values(newValidation).every(Boolean);
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Mark As Submitted</ModalHeader>
            <ModalBody>
                {isEditor ? (<h4>Do not submit unless you have uploaded the photos to sirv!</h4>) : null}
                {isBlogger ? (
                    <Form>
                        <FormGroup>
                            <Label for="overview">Overview</Label>
                            <Input type="textarea" name="overview" id="overview" onChange={handleInputChange} invalid={!validation.overview} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="interiorExterior">Interior/Exterior</Label>
                            <Input type="textarea" name="interiorExterior" id="interiorExterior" onChange={handleInputChange} invalid={!validation.interiorExterior} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="engine">Engine</Label>
                            <Input type="textarea" name="engine" id="engine" onChange={handleInputChange} invalid={!validation.engine} />
                        </FormGroup>
                    </Form>) : null}
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => submit()} color="primary">Submit</Button>
                <Button onClick={() => props.setModalState({ isOpen: false })}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default JobSubmitModal;
