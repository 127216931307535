import {createSlice} from "@reduxjs/toolkit";

const authStateInit = {
    loggedIn: false,
}

export const AuthSlice = createSlice({
    name: 'AuthSlice',
    initialState: authStateInit,
    reducers: {
        logout(state) {
            state.loggedIn = false;
            sessionStorage.removeItem('token');
        },
        loginSuccess(state, action) {
            state.loggedIn = true;
            sessionStorage.setItem("token", action.payload.token);
        },
    }
});
