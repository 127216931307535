import React, { useState } from "react";
import DateTimePicker from 'react-datetime-picker';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import {callRemote, PUT} from "../../../service/RemoteService";
import {shootsActions} from "../../../store/storeIndex";
import {displayAlert} from "../../../store/AlertSlice";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";
import moment from "moment";

export const ScheduleModal = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const [selectedOption, setSelectedOption] = useState(new Date());

    const scheduleShoot = async () => {
        props.shoot.shootDate = selectedOption.toISOString();
        callRemote(getAccessTokenSilently, PUT, "/shoots/" + props.shoot.id, { ...props.shoot })
        .then(() => {
            dispatch(shootsActions.incrementActionsTaken());
            dispatch(displayAlert(true, "shoot scheduled for " +
                moment(props.shoot.shootDate).format("ddd MM/DD/YYYY")));
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        }).finally(() => {
            props.getShoots();
            props.setModalState({ isOpen: false });
        })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Schedule Shoot</ModalHeader>
            <ModalBody>
                <DateTimePicker onChange={setSelectedOption} value={selectedOption} disableClock={true} format={"MM-dd-y h:mm a"} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => scheduleShoot()} color="primary">Schedule</Button>
                <Button onClick={() => props.setModalState({ isOpen: false })}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ScheduleModal;
