import React, { useEffect, useState, useCallback } from "react";
import Loading from "../../components/Loading";
import {Button, ButtonGroup, Table, UncontrolledTooltip} from "reactstrap";
import dayjs from 'dayjs'
import PaginationControls from "../photographers/tables/PaginationControls";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import 'react-tabs/style/react-tabs.css';
import { useDispatch } from "react-redux";
import { determineIfTokenError } from "../../store/AuthThunks";
import {callRemote, GET, PUT} from "../../service/RemoteService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import JobRejectModal from "./JobRejectModal";
import JobApproveModal from "./JobApproveModal";
import {TabList, TabPanel, Tabs, Tab} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import {displayAlert} from "../../store/AlertSlice";


export const ProfileComponent = () => {
    let dispatch = useDispatch();
    const { user, getAccessTokenSilently } = useAuth0();
    const [jobs, setjobs] = useState(null);
    const [pending, setPending] = useState(null);
    const [cancelled, setCancelled] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPendingPage, setCurrentPendingPage] = useState(0);
    const [currentCancelledPage, setCurrentCancelledPage] = useState(0);
    const [modalState, setModalState] = useState({ isOpen: false });
    const [approveModalState, setApproveModalState] = useState({ isOpen: false });

    const roles = user != null && user["http://exoticcartrader.com/role"];
    const isEditor = roles.includes('Editor Manager');

    const getJobs = useCallback(async (page) => {
        callRemote(getAccessTokenSilently, GET, "/jobs/submitted?pageNumber=" + page)
            .then((jobsResponse) => {
                setjobs(jobsResponse);
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            });
    }, [dispatch, getAccessTokenSilently]);

    const getPendingJobs = useCallback(async (page) => {
        callRemote(getAccessTokenSilently, GET, "/jobs/pending?pageNumber=" + page)
            .then((jobsResponse) => {
                setPending(jobsResponse);
            }).catch(e => {
            dispatch(determineIfTokenError(e));
        });
    }, [dispatch, getAccessTokenSilently])

    const getCancelledJobs = useCallback(async (page) => {
        callRemote(getAccessTokenSilently, GET, "/jobs/cancelled?pageNumber=" + page)
            .then((jobsResponse) => {
                setCancelled(jobsResponse);
            }).catch(e => {
            dispatch(determineIfTokenError(e));
        });
    }, [dispatch, getAccessTokenSilently])

    useEffect(() => {
        getJobs(currentPage);
        getPendingJobs(currentPendingPage);
        getCancelledJobs(currentCancelledPage)
    }, [currentPage, getJobs, getPendingJobs, currentPendingPage, getCancelledJobs, currentCancelledPage]);

    const openModal = (job) => {
        setModalState({ isOpen: true, job: job });
    }
    const closeModal = () => {
        setModalState({ isOpen: false });
    }

    const openApproveModal = (job) => {
        setApproveModalState({ isOpen: true, job: job });
    }
    const closeApproveModal = () => {
        setApproveModalState({ isOpen: false });
    }

    const unAssignJob = async (job) => {
        job.creatorId = null;
        job.rejectionReason = null;
        job.rejectionDate = null;
        await callRemote(getAccessTokenSilently, PUT, "/jobs/" + job.id, { ...job });
        getPendingJobs(currentPendingPage);
    }

    const reactivateJob = async (job) => {
        job.creatorId = null;
        job.rejectionReason = null;
        job.rejectionDate = null;
        job.cancelledDate = null;
        await callRemote(getAccessTokenSilently, PUT, "/jobs/" + job.id, { ...job });
        dispatch(displayAlert(true, "Job Re-Activated"));
        getCancelledJobs(currentPendingPage);
    }

    return (
        <div>
            <Tabs>
                <TabList>
                    <Tab>Submitted</Tab>
                    <Tab>In Progress</Tab>
                    <Tab>Cancelled</Tab>
                </TabList>
                <TabPanel>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>Photos Submitted Date</th>
                            <th>Submitted By</th>
                            <th>Vehicle</th>
                            <th>VIN</th>
                            <th>Links</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>{jobs != null && jobs.jobs.map(job => (
                            <tr key={job.id}>
                                <td>{dayjs(job.photosSubmittedDate).format('MM/DD/YYYY h:mm a')}</td>
                                <td>{job.email}</td>
                                <td>{job.car}</td>
                                <td>{job.vin}</td>
                                {isEditor ? (<td><a href={`https://my.sirv.com/#/browse${job.sirvFolder}`} target="_blank"
                                                    rel="noopener noreferrer">Review Edited Photos</a></td>) :
                                    (<td><a href={job.photosLink} target="_blank" rel="noopener noreferrer">Photographer Photos</a><br/>
                                        <a href={job.instructionLink} target="_blank" rel="noopener noreferrer">Listing Instructions</a></td>)}
                                <td><ButtonGroup>

                                    <Button id={'approveBtn'} onClick={() => {
                                        openApproveModal(job)
                                    }} color="success">
                                        <FontAwesomeIcon icon="check"/>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target="approveBtn"
                                            trigger="hover"
                                        >
                                            Approve
                                        </UncontrolledTooltip>
                                    </Button>
                                    <Button id={'rejectBtn'} onClick={() => {
                                        openModal(job)
                                    }} color="danger">
                                        <FontAwesomeIcon icon="ban"/>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target="rejectBtn"
                                            trigger="hover"
                                        >
                                            Reject
                                        </UncontrolledTooltip>
                                    </Button>
                                </ButtonGroup></td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    <PaginationControls getjobs={getJobs} jobs={jobs} currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}/>

                    {modalState.job && <JobRejectModal
                        isOpen={modalState.isOpen}
                        getJobs={() => getJobs(currentPage)}
                        closeModal={closeModal}
                        setModalState={setModalState}
                        job={modalState.job}
                    />}
                    {approveModalState.job && <JobApproveModal
                        isOpen={approveModalState.isOpen}
                        getJobs={() => getJobs(currentPage)}
                        closeModal={closeApproveModal}
                        setModalState={setApproveModalState}
                        job={approveModalState.job}
                    />}
                </TabPanel>
                <TabPanel>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>Photos Submitted Date</th>
                            <th>Grab Date</th>
                            <th>Assigned To</th>
                            <th>Vehicle</th>
                            <th>VIN</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>{pending != null && pending.jobs.map(job => (
                            <tr key={job.id}>
                                <td>{dayjs(job.photosSubmittedDate).format('MM/DD/YYYY h:mm a')}</td>
                                <td>{dayjs(job.grabDate).format('MM/DD/YYYY h:mm a')}</td>
                                <td>{job.email}</td>
                                <td>{job.car}</td>
                                <td>{job.vin}</td>
                                <td><ButtonGroup>
                                    <Button id={'rejectBtn'} onClick={() => {
                                        unAssignJob(job)
                                    }} color="danger">
                                        <FontAwesomeIcon icon="ban"/>
                                        <UncontrolledTooltip placement="top" target="rejectBtn" trigger="hover">
                                            Un-Assign
                                        </UncontrolledTooltip>
                                    </Button>
                                </ButtonGroup></td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    <PaginationControls getjobs={getPendingJobs} jobs={pending} currentPage={currentPendingPage}
                                        setCurrentPage={setCurrentPendingPage}/>

                </TabPanel>
                <TabPanel>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>Cancelled Date</th>
                            <th>Vehicle</th>
                            <th>VIN</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>{cancelled != null && cancelled.jobs.map(job => (
                            <tr key={job.id}>
                                <td>{dayjs(job.cancelledDate).format('MM/DD/YYYY h:mm a')}</td>
                                <td>{job.car}</td>
                                <td>{job.vin}</td>
                                <td><ButtonGroup>
                                    <Button id={'activateBtn'} onClick={() => {reactivateJob(job)}} color="primary">
                                        Re-Activate
                                        <UncontrolledTooltip placement="top" target="activateBtn" trigger="hover">
                                            Re-Activate
                                        </UncontrolledTooltip>
                                    </Button>
                                </ButtonGroup></td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    <PaginationControls getjobs={getCancelledJobs} jobs={cancelled} currentPage={currentCancelledPage}
                                        setCurrentPage={setCurrentCancelledPage}/>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <Loading />,
});
