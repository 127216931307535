import React, { useState } from "react";
import Select from 'react-select'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import {callRemote, POST} from "../../../service/RemoteService";
import {shootsActions} from "../../../store/storeIndex";
import {displayAlert} from "../../../store/AlertSlice";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";

export const PayModal = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const [selectedOption, setSelectedOption] = useState(null);

    const pay = async () => {
        callRemote(getAccessTokenSilently, POST, "/stripe/pay",
            { photographerId: props.photographerId, amount: selectedOption.value, shootId: props.shootId })
        .then(() => {
            dispatch(shootsActions.incrementActionsTaken());
            dispatch(displayAlert(true, "photographer paid"))
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        }).finally(() => {
            props.getShoots();
            props.setModalState({ isOpen: false });
        })
    }

    const options = [
        { value: 7500, label: '$75' },
        { value: 8500, label: '$85' },
        { value: 9500, label: '$95' },
        { value: 10000, label: '$100' },
        { value: 10500, label: '$105' },
        { value: 11000, label: '$110' },
        { value: 12000, label: '$120' },
        { value: 12500, label: '$125' },
        { value: 13000, label: '$130' },
        { value: 14000, label: '$140' },
        { value: 15000, label: '$150' },
        { value: 0, label: '$0 (No Payment)' },
    ];

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Pay Photographer</ModalHeader>
            <ModalBody>
                <Select options={options} onChange={setSelectedOption} />
                <Form>
                    <FormGroup>
                        <Label for="notes">Notes</Label>
                        <Input type="text" name="notes" id="notes" value={props.notes} readOnly/>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => pay(selectedOption.value)} color="primary" disabled={selectedOption == null}>Pay</Button>
                <Button onClick={() => props.setModalState({ isOpen: false })}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default PayModal;
