import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import Loading from "../components/Loading";


export const ProfileComponent = () => {

    return (
        <div>
            <h2>Please sign the photographer agreement sent to your email</h2>
        </div>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <Loading />,
});
