import React, { useState } from "react";
import Loading from "../components/Loading";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from "axios";
import { withRouter } from "react-router";

export const ProfileComponent = (props) => {
    const [eachEntry, setEachEntry] = useState({
        instagram: '',
        experience: '',
        experienceType: '',
        experienceCars: '',
        equipment: '',
        availability: '',
        onCall: '',
        background: '',
        dob: ''
    });
    const [validation, setValidation] = useState({
        instagram: true,
        experience: true,
        experienceType: true,
        experienceCars: true,
        equipment: true,
        availability: true,
        onCall: true,
        background: true,
        mediaCompany: true,
        dob: true
    });
    const { getAccessTokenSilently } = useAuth0();
    const submit = async (e) => {
        if(!validate()) {
            return;
        }
        const token = await getAccessTokenSilently({ audience: "https://team.exoticcartrader.com/" });
        await axios.post(process.env.REACT_APP_API_BASE_URL + "/photographers/apply", eachEntry, { headers: { Authorization: "Bearer " + token } });
        props.history.push('/sign-agreement');
    }

    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
        setValidation({ ...validation, [e.target.name]: e.target.value !== '' });
    };

    const handleMediaCompanyNo = e => {
        setEachEntry({ ...eachEntry, mediaCompany: false });
    };
    const handleMediaCompanyYes = e => {
        setEachEntry({ ...eachEntry, mediaCompany: true });
    };

    const validate = () => {
        const newValidation = {
            instagram: eachEntry.instagram !== '',
            experience: eachEntry.experience !== '',
            experienceType: eachEntry.experienceType !== '',
            experienceCars: eachEntry.experienceCars !== '',
            equipment: eachEntry.equipment !== '',
            availability: eachEntry.availability !== '',
            onCall: eachEntry.onCall !== '',
            background: eachEntry.background !== '',
            mediaCompany: eachEntry.mediaCompany !== undefined,
            dob: eachEntry.dob.length === 10
        };
        setValidation(
            newValidation
        )
        return Object.values(newValidation).every(Boolean);
    }

    return (
        <div>
            <h1>Photographer Application</h1>
            <Form>
                <FormGroup>
                    <Label for="instagram">Instagram</Label>
                    <Input type="text" name="instagram" id="instagram" placeholder="Instagram Username" onChange={handleInputChange} invalid={!validation.instagram}/>
                </FormGroup>
                <FormGroup tag="fieldset">
                    <Label>Do you already own a Media/Photography company</Label>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="mediaCompany" onChange={handleMediaCompanyYes} invalid={!validation.mediaCompany}/>{' '}
                            Yes
                        </Label>
                        <br />
                        <Label check>
                            <Input type="radio" name="mediaCompany" onChange={handleMediaCompanyNo} invalid={!validation.mediaCompany}/>{' '}
                            No
                        </Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="dob">Birth Date</Label>
                    <Input type="date" name="dob" id="dob" onChange={handleInputChange} invalid={!validation.dob}/>
                </FormGroup>
                <FormGroup>
                    <Label for="experience">How long have you been shooting for?</Label>
                    <Input type="text" name="experience" id="experience" onChange={handleInputChange} invalid={!validation.experience}/>
                </FormGroup>
                <FormGroup>
                    <Label for="experienceType">What type of photography do you usually shoot?</Label>
                    <Input type="text" name="experienceType" id="experienceType" onChange={handleInputChange} invalid={!validation.experienceType}/>
                </FormGroup>
                <FormGroup>
                    <Label for="experienceCars">Do you have any experience with cars or automotive content creation?</Label>
                    <Input type="text" name="experienceCars" id="experienceCars" onChange={handleInputChange} invalid={!validation.experienceCars}/>
                </FormGroup>
                <FormGroup>
                    <Label for="equipment">What kind of equipment do you use? Camera, lighting, etc..? Also, what Phone Make and Model do you use?</Label>
                    <Input type="text" name="equipment" id="equipment" onChange={handleInputChange} invalid={!validation.equipment}/>
                </FormGroup>
                <FormGroup>
                    <Label for="availability">What is your current availability to shoot if we hire you?</Label>
                    <Input type="text" name="availability" id="availability" onChange={handleInputChange} invalid={!validation.availability}/>
                </FormGroup>
                <FormGroup>
                    <Label for="onCall">Would you be able to be on call to shoot on short notice?</Label>
                    <Input type="text" name="onCall" id="onCall" onChange={handleInputChange} invalid={!validation.onCall}/>
                </FormGroup>
                <FormGroup>
                    <Label for="background">Do you have a clean background?</Label>
                    <Input type="text" name="background" id="background" onChange={handleInputChange} invalid={!validation.background}/>
                </FormGroup>
                <Button onClick={submit}>Submit</Button>
            </Form>
        </div>
    );
};

export default withAuthenticationRequired(withRouter(ProfileComponent), {
    onRedirecting: () => <Loading />,
});
