import React from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import {shootsActions} from "../../../store/storeIndex";
import {useDispatch} from "react-redux";
import {callRemote, PUT} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";

export const PhotographerCancelModal = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently, user } = useAuth0();

    const submit = async () => {
        const shoot = props.shoot;
        shoot.photographerId = null;
        shoot.notes = "Previously cancelled by " + user.given_name + " " + user.family_name;
        callRemote(getAccessTokenSilently, PUT, "/shoots/" + shoot.id, { ...shoot })
        .then(() => {
            dispatch(shootsActions.incrementActionsTaken());
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        }).finally(() => {
            props.getShoots();
            props.closeModal();
        })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Cancel Shoot</ModalHeader>
            <ModalBody>
                <h2>Do you really want to cancel this shoot?</h2>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => submit()} color="success">Yes</Button>
                <Button onClick={() => props.setModalState({ isOpen: false })} color="danger">No</Button>
            </ModalFooter>
        </Modal>
    );
};

export default PhotographerCancelModal;
