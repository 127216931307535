import React from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import {callRemote, POST} from "../../../service/RemoteService";
import {shootsActions} from "../../../store/storeIndex";
import {displayAlert} from "../../../store/AlertSlice";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";

export const SubmitModal = (props) => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const submit = async () => {
        props.shoot.submitted = true
        callRemote(getAccessTokenSilently, POST, "/shoots/" + props.shoot.id + "/submit", {})
        .then(() => {
            dispatch(shootsActions.incrementActionsTaken());
            dispatch(displayAlert(true, "shoot submitted"));
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        }).finally(() => {
            props.getShoots(0);
            props.setModalState({ isOpen: false });
        })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Mark As Submitted</ModalHeader>
            <ModalBody>
                <h4>Do not submit unless you have uploaded the photos to DropBox!</h4>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => submit()} color="primary">Submit</Button>
                <Button onClick={() => props.setModalState({ isOpen: false })}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default SubmitModal;
