import React from "react";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import { withRouter } from "react-router";

export const ProfileComponent = (props) => {
    const { user, getAccessTokenSilently } = useAuth0();

    const checkStripeSetup = async () => {
        const token = await getAccessTokenSilently({ audience: "https://team.exoticcartrader.com/" });
        const getStripeIdResponse = await axios.get(process.env.REACT_APP_API_BASE_URL + "/stripe/status", { headers: { Authorization: "Bearer " + token } });
        if (!getStripeIdResponse.data.enabled) {
            const getStripeIdResponse = await axios.get(process.env.REACT_APP_API_BASE_URL + "/stripe/", { headers: { Authorization: "Bearer " + token } });
            const getStripeLinkResponse = await axios.get(process.env.REACT_APP_API_BASE_URL + "/stripe/" + getStripeIdResponse.data.accountId, { headers: { Authorization: "Bearer " + token } });
            window.location = getStripeLinkResponse.data.url
        } else {
            props.history.push('/');
        }
    }
    
    const roles = user != null && user["http://exoticcartrader.com/role"];
    if (roles.includes("Photographer")) {
        checkStripeSetup();
    } else {
        props.history.push('/');
    }

    return (
        <div>
            <Loading/>
        </div>
    );
};

export default withAuthenticationRequired(withRouter(ProfileComponent), {
    onRedirecting: () => <Loading />,
});
