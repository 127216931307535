import React, { useState, useEffect, useCallback } from "react";
import { Button, Table } from "reactstrap";
import dayjs from 'dayjs'
import PayModal from "../modals/PayModal";
import RejectModal from "../modals/RejectModal";
import PaginationControls from "./PaginationControls";
import { useAuth0 } from "@auth0/auth0-react";
import {callRemote, GET} from "../../../service/RemoteService";
import {determineIfTokenError} from "../../../store/AuthThunks";
import {useDispatch} from "react-redux";

export const SubmittedTable = () => {
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const [currentPage, setCurrentPage] = useState(0);
    const [shoots, setShoots] = useState();
    const [payModalState, setPayModalState] = useState({ isOpen: false });
    const [rejectModalState, setRejectModalState] = useState({ isOpen: false });

    const getShoots = useCallback(async ()=> {
        callRemote(getAccessTokenSilently, GET, "/shoots/?status=submitted&pageNumber=" + currentPage)
        .then((shootsResponse) => {
            setShoots(shootsResponse);
        }).catch(e => {
            dispatch(determineIfTokenError(e));
        });
    }, [currentPage, dispatch, getAccessTokenSilently])

    useEffect(() => {
        getShoots();
    }, [getShoots]);

    const openPayModal = async (shootId, photographerId, notes) => {
        setPayModalState({ isOpen: true, photographerId: photographerId, shootId: shootId, notes: notes });
    }
    const closePayModal = () => {
        setPayModalState({ isOpen: false });
    }

    const openRejectModal = async (shootId) => {
        setRejectModalState({ isOpen: true, shootId: shootId });
    }
    const closeRejectModal = () => {
        setRejectModalState({ isOpen: false });
    }


    return (
        <div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Submitted Date</th>
                        <th>Vehicle</th>
                        <th>Customer</th>
                        <th>Photographer</th>
                        <th>Actions</th>
                        <th>Links</th>
                    </tr>
                </thead>
                <tbody>{shoots != null && shoots.shoots.map(shoot => (
                    <tr key={shoot.id} >
                        <td>{dayjs(shoot.submittedDate).format('MM/DD/YYYY h:mm a')}</td>
                        <td>{shoot.car}</td>
                        <td>{shoot.customerName}</td>
                        <td>{shoot.photographerName}</td>
                        <td>
                            <Button onClick={() => openPayModal(shoot.id, shoot.photographerId, shoot.notes)} color="primary">Pay</Button>
                            <Button onClick={() => openRejectModal(shoot.id)} color="danger">Reject</Button>
                        </td>
                        <td><a href={shoot.shareableLink} target="_blank" rel="noopener noreferrer">Review Photos</a></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <PaginationControls getShoots={getShoots} shoots={shoots} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            <PayModal
                photographerId={payModalState.photographerId}
                shootId={payModalState.shootId}
                notes={payModalState.notes}
                isOpen={payModalState.isOpen}
                getShoots={() => getShoots(currentPage)}
                closeModal={closePayModal}
                setModalState={setPayModalState}
            />
            <RejectModal
                shootId={rejectModalState.shootId}
                isOpen={rejectModalState.isOpen}
                getShoots={() => getShoots(currentPage)}
                closeModal={closeRejectModal}
                setModalState={setRejectModalState}
            />
        </div>
    );
};

export default SubmittedTable;
