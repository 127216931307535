import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withRouter } from "react-router";
import MyJobsTable from "./tables/MyJobsTable"
import MySubmittedJobsTable from './tables/MySubmittedJobsTable'

export const ProfileComponent = () => {
  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>Active Jobs</Tab>
          <Tab>Submitted Jobs</Tab>
        </TabList>

        <TabPanel><MyJobsTable /></TabPanel>
        <TabPanel><MySubmittedJobsTable /></TabPanel>
      </Tabs>

    </div>
  );
};

export default withAuthenticationRequired(withRouter(ProfileComponent), {
  onRedirecting: () => <Loading />,
});
