import React, { useState } from "react";
import { Button, Table } from "reactstrap";
import states from "./states.json";
import axios from "axios";

import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";

export const ProfileComponent = () => {
  const [photographers, setPhotographers] = useState();
  const getPhotographers = (state) => {
    if (state == null) {
      return;
    }
    axios.get(process.env.REACT_APP_API_BASE_URL + "/photographers/?state=" + state).then((res) => setPhotographers(res.data));
  }

  const setNewValue = (event) => {
    console.log(event.target.value);
    getPhotographers(event.target.value);
  }

  const formatPhoneNumber = (phoneNumberString) =>{
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  return (
    <div>
      <div style={{display: "flex"}}>
      <h2>Photographer Search</h2>
        <select onChange={setNewValue}  style={{margin: "10px"}} defaultValue="Select State">
        <option key="default" value="default" hidden>
              Select a State
            </option>
          {states.map(item => (
            <option key={item.abbreviation} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <Table dark>
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>Phone</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {
            photographers != null && photographers.map(p => (
              <tr key={p.id}>
                <td>{p.firstName + " " + p.lastName}</td>
                <td>{p.city}</td>
                <td>{formatPhoneNumber(p.phone)}</td>
                <td>{p.email}</td>
                <td><Button>Book</Button></td>
              </tr>
            ))
          }
        </tbody>
      </Table>

    </div>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
