import React, {useState} from "react";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {shootsActions} from "../../store/storeIndex";
import {displayAlert} from "../../store/AlertSlice";
import {useDispatch} from "react-redux";
import {callRemote, POST} from "../../service/RemoteService";
import {determineIfTokenError} from "../../store/AuthThunks";

export const JobRejectModal = (props) => {
    const job = props.job;
    let dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const [reason, setReason] = useState(null);
    const isEditor = job.jobType === "Editor";
    const isBlogger = job.jobType === "Blogger";

    const submit = async () => {
        job.creatorId = null;

        callRemote(getAccessTokenSilently, POST, "/jobs/" + props.job.id + "/reject", { reason: reason })
            .then(() => {
                dispatch(shootsActions.incrementActionsTaken());
                dispatch(displayAlert(true, "Rejected Job"))
            }).catch(e => {
                dispatch(determineIfTokenError(e));
        }).finally(() => {
            props.getJobs();
            props.setModalState({ isOpen: false });
        })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.closeModal}>
            <ModalHeader toggle={props.closeModal}>Review and Reject Submission</ModalHeader>
            <ModalBody>
                {isEditor && <>
                    <h4>Notice: You are about to reject the submitted photos. This action will return the job to the
                        photo editor for necessary corrections.</h4>
                    <Form>
                        <FormGroup>
                            <Label for="reason">Identify Issues: Please detail any problems with the photos or file
                                names in the space provided below.<br/><br/>
                                This will help the editor make the required changes.
                                After listing the corrections, click the “Reject” button to send the job back to the
                                editor.</Label>
                            <Input type="textarea" name="reason" id="reason"
                                   onChange={(e) => setReason(e.target.value)}/>
                        </FormGroup>
                    </Form>
                </>}
                {isBlogger &&
                    <>
                        <h4>Notice: you are about to reject the submitted write-up.  This action will return the job to the original writer for necessary corrections.</h4>
                        <Form>
                            <FormGroup>
                                <Label for="reason">Identify Issues: Please detail any problems with the write-up in the
                                    space below.<br/><br/>
                                    This will help the writer make the required changes. After listing the corrections,
                                    click the “Reject” button to send the job back to the writer.<br/><br/>
                                    NOTE: You make make minor corrections directly on your own if you click “Cancel” and then click “Approve.”  The corrections you make will be officially submitted.
                                </Label>
                                <Input type="textarea" name="reason" id="reason"
                                       onChange={(e) => setReason(e.target.value)}/>
                            </FormGroup>
                        </Form>
                    </>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => submit()} color="success">Reject</Button>
                <Button onClick={() => props.setModalState({isOpen: false})} color="danger">Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default JobRejectModal;
